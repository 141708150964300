/* ============================================================
 * Play a Video covered by button
 * ============================================================ */

document.addEventListener(
  'DOMContentLoaded',
  function() {
    const videoContainers = document.querySelectorAll('.js-vid-container');

    if (videoContainers) {
      videoContainers.forEach((container) => {
        const playButton: any = container.querySelector('.js-vid-play');
        const overlay: any = container.querySelector('.js-vid-overlay');
        const video: any = container.querySelector('.js-vid');
        if (playButton) {
          playButton.addEventListener('click', function(e) {
            e.preventDefault();
            video.src += '&autoplay=1';
            video.setAttribute('aria-hidden', false);
            video.style.opacity = '1';
            video.tabIndex = 0;
            setTimeout(() => {
              overlay.remove();
              // video.focus();
            }, 300);
          });
        }
      });
    }
  },
  false,
);
