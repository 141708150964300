import ScrollMagic from 'scrollmagic';

document.addEventListener('DOMContentLoaded', () => {
  const controller = new ScrollMagic.Controller();
  const scrollItems = document.querySelectorAll('.js-animate');

  scrollItems.forEach((item) => {
    new ScrollMagic.Scene({ triggerElement: item })
      .triggerHook('onEnter')
      .removeClassToggle(false)
      .setClassToggle(item, 'is-show')
      .addTo(controller);
  });
});
