import { displayErrorsObject } from './form-errors';

document.addEventListener('DOMContentLoaded', async () => {
  const form = document.querySelector('form#checkout-form') as HTMLFormElement;

  // ------- Form submit -------
  form?.addEventListener('submit', async (e) => {
    e.preventDefault();

    const postData = {
      cart: window.cart,
      _csrf: form['_csrf'].value,
    };

    try {
      // TODO: can I use an adonis route helper here?
      const response = await fetch('/stripe/checkout', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      const json = await response.json();

      // Display any errors returned from the backend
      if (!response.ok) {
        displayErrorsObject(form, json.errors);
      } else {
        // Navigate to the newly-created checkout session url
        window.location.href = json.url;
      }
    } catch (error) {
      console.error(error);
    }
  });
});
