/* ------ Set the CSS variables ------- */

function setVH() {
  // console.debug('Setting vh variable');
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  const documentElement: any = document.getElementsByTagName('BODY')[0];
  // Then we set the value in the --vh custom property to the root of the document
  documentElement.style.setProperty('--vh', `${vh}px`);
}

setVH();

// Chrome on Android vh sets incorrect value on initial load
setTimeout(setVH, 100);

window.addEventListener('resize', setVH);
