// Bind close button listener for IE banner

document.addEventListener('DOMContentLoaded', () => {
  const ieBanner = document.getElementById('ie-banner');
  const ieBannerCloseBtn = document.getElementById('ie-banner-close');
  if (!ieBanner || !ieBannerCloseBtn) {
    return;
  }

  ieBannerCloseBtn.addEventListener('click', () => {
    ieBanner.classList.add('hidden');
  });
});
