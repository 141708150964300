import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';

/** Generate the URL for a given search query */
const genSearchUrl = (query: string) => {
  const queryParam = encodeURIComponent(query);
  const url = `/search?q=${queryParam}`;
  return url;
};

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
  key: 'RECENT_SEARCH',
  limit: 3,
  transformSource({ source }) {
    return {
      ...source,
      getItemUrl({ item }) {
        const url = genSearchUrl(item.id);
        return url;
      },
      templates: {
        ...source.templates,
        item(params) {
          const { item, html } = params;

          return html`<a
            class="aa-ItemLink"
            href="${genSearchUrl(item.id)}"
          >
            ${/* TODO: For some reason it uses ReverseHighlight by default and I can't find a good way to change it...
              components.Highlight({
                hit: item,
                attribute: 'id',
              })*/
              // @ts-ignore: This is just from the docs...
              source.templates.item(params).props.children}
          </a>`;
        },
      },
    };
  },
});

const searchClient = algoliasearch(
  // TODO: Can I use the env values somehow?
  'NOZ1QDJQX7', // App ID
  '7a90c3feb1020c684066202bacc30669' // Search key
);

document.addEventListener('DOMContentLoaded', () => {
  autocomplete({
    container: '#autocomplete',
    panelContainer: '#autocomplete-panel',
    panelPlacement: 'full-width',
    placeholder: 'Search for products, collections, topics…',
    plugins: [recentSearchesPlugin],
    // Never use detached mode (https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/detached-mode)
    detachedMediaQuery: 'none',
    // debug: true, // keep the results list open
    getSources({ query }) {
      return [
        {
          sourceId: 'products',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: 'shopify_products',
                  query,
                  params: {
                    hitsPerPage: 6,
                  },
                },
              ],
            });
          },
          templates: {
            item({ item, components, html }) {
              return html`<a class="aa-ItemWrapper" href="/products/${item.handle}">
                <div class="aa-ItemContent">
                  <div class="aa-ItemIcon aa-ItemIcon--alignTop">
                    <svg class="aa-SearchIcon" viewBox="0 0 24 24" width="20" height="20" fill="currentColor"><path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"></path></svg>
                  </div>
                  <div class="aa-ItemContentBody">
                    <div class="aa-ItemContentTitle">
                      ${components.Highlight({
                        hit: item,
                        attribute: 'title',
                      })}
                    </div>
                  </div>
                  <div class="aa-ItemActions">
                    <button
                      class="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                      type="button"
                      title="Select"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        fill="currentColor"
                      >
                        <path
                          d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </a>`;
            },
          },
          getItemUrl({ item }) {
            return `/products/${item.handle}`;
          },
          // Apparently this doesn't fire if you just try and search without selecting an item,
          // so we need to add our own handler
          // onSubmit: (data) => {
          //   console.debug('Algolia onSubmit', data);
          //   // window.location.href = `/search?q=${item.handle}`;
          // },
        },
      ];
    },
  });

  // Add custom event listener for pressing enter on the search box

  // TODO: Is this safe or do I need to wait for the DOM to be ready?
  const input = document.querySelector('#autocomplete .aa-Input');
  input?.addEventListener('keydown', function(event: KeyboardEvent) {
    // console.debug('keydown', event.key);

    // Check if the key pressed was 'Enter'
    if (event.key === 'Enter') {
      // Prevent default so we handle saving as a recent search ourselves
      event.preventDefault();

      const searchInput = event.target as HTMLInputElement;

      // Abort if selecting an item from the dropdown
      const selectedItem = document.querySelector('.aa-Item[aria-selected="true"]');
      if (selectedItem) {
        return;
      }

      // Add it as a recent search (it automatically dedupes + sorts)
      recentSearchesPlugin.data!.addItem({
        id: searchInput.value,
        label: searchInput.value,
      });

      // Navigate to the search page
      const url = genSearchUrl(searchInput.value);
      window.location.href = url;
    }
  });
});
