'use strict';

// Replace this API key
const API_KEY = 'AIzaSyACeL0ltu60rAtGGegL7p0iaUki_RKM30k';

/**
 * Add the Google Maps script tag and loads the API
 * @returns The maps API (window.google.maps)
 */
function loadApi() {
  return new Promise<typeof google.maps>((resolve, reject) => {
    if (!mapsExists()) {
      // console.log('loading maps');
      const script = document.createElement('script');

      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        resolve(window.google.maps as typeof google.maps);
      };

      script.onerror = reject;
      script.onabort = reject;

      document.head.appendChild(script);
    } else {
      // console.log('already have maps');
      resolve(window.google.maps as typeof google.maps);
    }
  });
}

/**
 * This is called from inside client JS, and contains all of
 * your maps API logic
 * @param styles Snazzy Maps styles json file
 */
export async function initMaps(styles: any[] = []) {
  let mapsApi: typeof google.maps;
  try {
    mapsApi = await loadApi();
    // console.log('loaded maps');
  } catch (err) {
    throw new Error('Could not load Google Maps API');
  }

  // ---------------
  //  Your maps logic starts here:
  // ---------

  let maps = document.querySelectorAll('.js-map') as NodeListOf<HTMLElement>;

  maps.forEach((map) => {
    initMap(map);
  });

  let map: google.maps.Map;

  function initMap(elem: HTMLElement) {
    let locations = elem.querySelectorAll('.js-location');

    const lon = Number(elem.getAttribute('data-lng'));
    const lat = Number(elem.getAttribute('data-lat'));

    let windowWidth = window.innerWidth;
    let zoomLevel = (windowWidth > 767)
      ? 11
      : 11;

    let mapOptions = {
      zoom: zoomLevel,
      center: { lat: lat, lng: lon },
      mapTypeId: 'roadmap',
      disableDefaultUI: true,
      zoomControl: false,
      styles,
    };

    map = new mapsApi.Map(elem, mapOptions);

    /*
    window.addEventListener('resize', _throttle(() => {
      // Update zoom level
      const zoom = location.offsetWidth / 500;
      // console.log('setting zoom to:', zoom);
      map.setZoom(zoom);

      // If you add your markers to an array, you can also control their size
      // let isSmall = window.innerWidth < 600;
      // let markerSize = isSmall ? 24 : 32;
      // markers.forEach(marker => {
      //   marker.setIcon({
      //     url: marker.icon.url,
      //     scaledSize: new mapsApi.Size(markerSize, markerSize),
      //   });
      // });
    }, 100));
    */

    locations?.forEach((location: HTMLElement) => {
      let lon = location.dataset.lng;
      let lat = location.dataset.lat;
      addMarker(location, lon, lat);
    });
  }

  function addMarker(location: HTMLElement, lon, lat) {
    let latLng = new mapsApi.LatLng(lat, lon);

    return new mapsApi.Marker({
      position: latLng,
      map: map,
      title: location.getAttribute('data-title'),
    });
  }

  // toggle map showing and not showing on mobile

  let mapBtns = document.getElementsByClassName('js-show-map');
  let i;

  for (i = 0; i < mapBtns.length; i++) {
    mapBtns[i].addEventListener('click', function() {
      this.classList.toggle('active');
      let panel = this.parentNode.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    });
  }
}

/**
 * Check if the Google Maps API has been loaded
 * @returns {boolean} If the Google Maps API has been loaded or not
 */
export function mapsExists() {
  return typeof window.google === 'object' && typeof window.google.maps === 'object';
}
