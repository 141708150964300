import $ from 'jquery';

const CONTAINER_SELECTOR = '.product-list, .featured-product';

$(function() {
  $(CONTAINER_SELECTOR).on('mouseover', '.js-product', (e)=> {
    const $card = e.currentTarget;
    $card.classList.add('hover');
  });

  $(CONTAINER_SELECTOR).on('mouseout', '.js-product', (e)=> {
    const $card = e.currentTarget;
    $card.classList.remove('hover');
  });

  $(CONTAINER_SELECTOR).on('focusin', '.js-product', (e)=> {
    const $card = e.currentTarget;
    $card.classList.add('hover');
  });

  $(CONTAINER_SELECTOR).on('blur', '.js-product', (e)=> {
    const $card = e.currentTarget;
    $card.classList.remove('hover');
  });
});
