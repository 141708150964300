import Flickity from 'flickity';

const getNodeIndex = (elm) => [...elm.parentNode.children].indexOf(elm);

export default function updateVariantImage(id) {
  const slider = document.querySelector('.js-product-gallery');

  if (slider) {
    const flkty = new Flickity('.js-product-gallery');
    const slide = slider?.querySelector(`.js-slide[data-variants*="${id}"`);

    if (slide) {
      const index = getNodeIndex(slide);
      flkty.select(index);
    }
  }
}
