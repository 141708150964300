import flatpickr from 'flatpickr';

document.addEventListener('DOMContentLoaded', async () => {
  const dateInputs = document.querySelectorAll('.input__date');

  dateInputs.forEach((input) => {
    flatpickr(input, {
      altInput: false,
      dateFormat: 'F j, Y',
      minDate: 'today',
    });
  });
});
