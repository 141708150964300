import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

/**
 * Get all tabbable elements on the page
 * @returns All tabbable elements on the page
 */
function getPageButtons() {
  return document.querySelectorAll(`
    select:not([tabindex="-1"]),
    input:not([tabindex="-1"]),
    textarea:not([tabindex="-1"]),
    button:not([tabindex="-1"]),
    a:not([tabindex="-1"]),
    iframe:not([tabindex="-1"])
  `);
}

/**
 * Check if modal is allowed to exit via esc or clicking off
 * @param modal The modal to check
 */
function isExitAllowed(modal: HTMLElement) {
  // If has no attribute, allow exit
  const hasAttribute = modal.hasAttribute('data-locked');
  if (!hasAttribute) {
    return true;
  }

  // If attribute is false, allow exit
  if (['false', '0'].includes(modal.dataset.locked || '')) {
    return true;
  }

  // If attribute is true, disallow exit
  return false;
}

/**
 * Open a modal, lock scrolling, disable tabbing to elements outside modal, and focus on first element inside modal
 * @param modalName Name of the modal (the data-modal attribute)
 */
function openModal(modalName: string, image: any = null) {
  const modal = document.querySelector(`.js-modal[data-modal="${modalName}"]`);
  if (!modal) {
    return;
  }

  // disable scrolling everywhere except modal content
  const targetElement = document.querySelector(`.js-modal[data-modal='${modalName}'] .modal__content`) as HTMLElement;

  if (targetElement) {
    disableBodyScroll(targetElement);
  }

  if (image) {
    let img = document.createElement('img');
    img.src = image;
    img.classList.add('modal__img');

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      targetElement.style.aspectRatio = `${width} / ${height}`;
      targetElement.appendChild(img);
    };
  }

  // show the modal
  modal.classList.add('modal--is-visible');
  modal.setAttribute('aria-hidden', 'false');

  // set tabindex of all clickable elements outside modal to -1
  const pageBtns = getPageButtons();
  pageBtns.forEach((btn) => {
    btn.setAttribute('tabindex', '-1');
  });

  // set tabindex of modal clickable elements to 0
  const modalLinks = modal.querySelectorAll('select, input, textarea, button, a, iframe') as NodeListOf<HTMLElement>;
  modalLinks.forEach((link) => {
    link.setAttribute('tabindex', '0');
  });
  modalLinks[0]?.focus();
}

/**
 * Close a modal, unlock scrolling, enable tabbing to elements outside modal, and focus on original element
 * @param modalName Name of the modal (the data-modal attribute)
 * @param origFocus The element that was focused before the modal opened
 */
function closeModal(modalName: string | undefined, origFocus?: HTMLElement) {
  const modal = document.querySelector(`.js-modal[data-modal="${modalName}"]`);
  if (!modal) {
    return;
  }

  clearAllBodyScrollLocks();

  // set tabindex of page elements back to 0
  const pageBtns = getPageButtons();
  pageBtns.forEach((btn) => {
    btn.setAttribute('tabindex', '0');
  });

  if (modal) {
    // hide the modal
    modal.classList.remove('modal--is-visible');
    modal.setAttribute('aria-hidden', 'true');

    // set tabindex of modal elements back to -1
    let modalLinks = modal.querySelectorAll('select, input, textarea, button, a, iframe');
    modalLinks.forEach((link) => {
      link.setAttribute('tabindex', '-1');
    });

    let img = modal.querySelector('.modal__img');

    if (img) {
      setTimeout(() => {
        img?.remove();
        let modalContent = modal.querySelector<HTMLElement>('.modal__content');
        if (modalContent) {
          modalContent.style.aspectRatio = '';
        }
      }, 500);
    }
  }

  // move focus back to original focus before modal opened
  if (origFocus) {
    origFocus.focus();
  }

  // Emit modal close event
  modal.dispatchEvent(new CustomEvent('close', { bubbles: true, detail: { modal: modalName } }));
}

// The actual event listener to hook it all up
document.addEventListener('DOMContentLoaded', () => {
  let origFocus;

  // open modal when clicking on button
  const jsModalBtns = document.querySelectorAll('.js-modal-btn');
  jsModalBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      origFocus = e.target;
      const modalName = btn.getAttribute('data-modal');
      const imageSrc = btn.getAttribute('data-src');
      if (modalName) {
        openModal(modalName, imageSrc);
      }
    });
  });

  // close modal when clicking on close button
  const jsModalClose = document.querySelectorAll('.js-modal-close');
  jsModalClose.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const modal = btn.closest('.js-modal');
      if (modal) {
        closeModal((modal as HTMLElement).dataset.modal, origFocus);
      }
    });
  });

  // close modal when clicking on overlay
  const jsModal = document.querySelectorAll('.js-modal');
  jsModal.forEach((modal) => {
    modal.addEventListener('click', (e) => {
      // make sure click is on 'background'
      if (!(e.target as HTMLElement).closest('.modal__content')) {
        if (isExitAllowed(modal as HTMLElement)) {
          closeModal((e.target as HTMLElement).dataset.modal, origFocus);
        } else {
          console.debug('Modal is locked, cannot close');
        }
      }
    });
  });

  // Close modal when pressing ESC
  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      const modal = document.querySelector('.modal--is-visible');
      if (modal) {
        if (isExitAllowed(modal as HTMLElement)) {
          closeModal((modal as HTMLElement).dataset.modal, origFocus);
        } else {
          console.debug('Modal is locked, cannot close');
        }
      }
    }
  });
});

export default {
  openModal,
  closeModal,
};

export {
  openModal,
  closeModal,
};
