// Module that displays adonis errors in a UL in the form.
// TODO: It would probably be a good idea to either use a validation library for this,
// or to flesh it out more (e.g. put the error messages next to the fields).

import { FormErrors } from 'App/Types/ApiData';

// TODO: Use this format across the app
export const displayErrorsObject = (
  form: HTMLFormElement,
  errors: FormErrors,
) => {
  const errorsWrapper = form.querySelector('.js-form-errors');
  if (!errorsWrapper) {
    console.error(errors);
    return;
  }

  // Clear previous errors
  errorsWrapper.innerHTML = '';

  // Create a UL to hold the errors
  const errorList = document.createElement('ul');
  errorList.classList.add('errors-list');
  errorsWrapper.appendChild(errorList);

  // Generate an LI for each error
  Object.keys(errors).forEach((key) => {
    const li = document.createElement('li');
    li.textContent = `${key}: ${errors[key]}`;
    errorList.appendChild(li);
  });
};
