'use strict';

import { scrollIntoView } from 'seamless-scroll-polyfill';
import Flickity from 'flickity';

document.addEventListener(
  'DOMContentLoaded',
  function() {
    const navBp: number = 1200;
    const nav: any = document.querySelector('#js-nav');
    const toggleBtn = nav.querySelector('.js-toggle-nav');
    const mainNavList = nav.querySelector('.nav__list--level-1');
    const navDropdownLinks = nav.querySelectorAll('a[data-dropdown="true"]');
    const navLinksAll = nav.querySelectorAll('a, button');
    const mobileNavMenu = nav.querySelector('.js-mobile-nav');
    const backBtns = nav.querySelectorAll('.js-nav-back');
    const announcementBanner = document.querySelector('.js-announcement-banner');

    const searchBtns = [].slice.call(document.querySelectorAll('.js-nav-search'));
    const searchBox: any = document.querySelector('.js-search-box');
    const searchInputs = searchBox ? [].slice.call(searchBox.querySelectorAll('a, button, input')) : [];
    const searchCloseBtn = searchBox ? searchBox.querySelector('.js-search-close') : undefined;
    let clickedSearchBtn: any;

    /* -----------
		* Functions
		----------- */

    /* --- Toggle mobile nav open and closed --- */
    function toggleNav() {
      if (nav.classList.contains('open')) {
        // close (this re-enables body scroll)
        closeMobileNav();
      } else {
        // open
        closeSearchBox();
        const bodyRect: any = document.body.getBoundingClientRect();
        const navRect: any = nav.getBoundingClientRect();
        const topOfNav: number = navRect.top - bodyRect.top;

        const windowPosition: number = window.scrollY || window.pageYOffset;

        const disableScrolling = () => {
          // console.debug('Disabling vanilla body scroll');
          const body = document.querySelector('body');
          if (body) {
            body.style.overflow = 'hidden';
          }
        };

        if (windowPosition !== topOfNav) {
          // scroll to top of nav before opening
          scrollIntoView(nav, { behavior: 'smooth' });
          setTimeout(function() {
            nav.classList.add('open');
            closeSubMenus();
            disableScrolling();
          }, 500);
        } else {
          nav.classList.add('open');
          disableScrolling();
        }
      }
    }

    /* --- Close mobile nav --- */
    function closeMobileNav() {
      closeSearchBox();
      nav.classList.remove('open');

      // console.debug('Enabling vanilla body scroll');
      const body = document.querySelector('body');
      if (body) {
        body.style.overflow = '';
      }

      // reset any sub menus that were opened
      setTimeout(function() {
        closeSubMenus();
      }, 300); // set the time to be the same as the css animation to close the nav
    }

    /* --- Close submenus if focusing on a different nav link --- */
    function closeSubMenusOnFocus() {
      const openDropdown = document.querySelector('.nav__dropdown.open');
      const linkInDropdown = openDropdown && openDropdown.contains(this) ? true : false;
      const linkInSearch = searchBox.contains(this) ? true : false;
      const mobileOpenSearchBtn = this.classList.contains('js-nav-search') && window.matchMedia(`(max-width: ${navBp - 1}px)`).matches ? true : false;
      if (!this.classList.contains('open') && !linkInDropdown && !linkInSearch && !mobileOpenSearchBtn) {
        closeSubMenus();
      }
    }

    /* --- Open the submenu for the selected link (mobile & desktop) --- */
    function openSubMenu(e) {
      closeSubMenus(); // close all open sub menus first
      const link = e.target;

      if ((e.type === 'mouseover' && window.matchMedia(`(min-width: ${navBp}px)`).matches) || e.type === 'click') {
        const dropdown = link.nextElementSibling;

        link.classList.toggle('open');
        mobileNavMenu.classList.add('sub-menu-open');
        dropdown.classList.add('open');
        dropdown.setAttribute('aria-hidden', false);
      }
    }

    /* --- Close the open submenus (mobile & desktop) --- */
    function closeSubMenus() {
      const links = nav.querySelectorAll('.nav__link.open');
      const dropdowns = nav.querySelectorAll('.nav__dropdown.open');

      mobileNavMenu.classList.remove('sub-menu-open');

      links.forEach((link) => {
        link.classList.remove('open');
      });
      dropdowns.forEach((dropdown) => {
        dropdown.classList.remove('open');
        dropdown.setAttribute('aria-hidden', true);
      });
    }

    /* --- Toggle sub menus open and closed (mobile & desktop) --- */
    function toggleSubMenu(e) {
      e.preventDefault();

      if (!this.classList.contains('open')) {
        openSubMenu(e);
      } else {
        closeSubMenus();
      }
    }

    /* --- Open the search box --- */
    function openSearchBox() {
      if (!searchBox) {
        return;
      }

      if (nav.classList.contains('open')) {
        searchBox.style.maxHeight = 'calc(var(--vh, 1vh) * 100 - 6.4rem)';
      } else {
        searchBox.style.maxHeight = searchBox.scrollHeight + 'px';
      }
      searchBox.setAttribute('aria-hidden', 'false');
      searchInputs.forEach((input) => {
        input.setAttribute('tabindex', '0');
      });
      searchBox.querySelector('.aa-Input')?.focus();
      clickedSearchBtn = this;
    }

    /* --- Close the search box --- */
    function closeSearchBox() {
      if (!searchBox) {
        return;
      }

      searchBox.style.maxHeight = null;
      searchBox.setAttribute('aria-hidden', 'true');
      searchInputs.forEach((input) => {
        input.setAttribute('tabindex', '-1');
      });
      if (clickedSearchBtn) {
        clickedSearchBtn.focus();
        clickedSearchBtn = undefined;
      }
    }

    /* --- Resize the search box when screen size changes --- */
    function resizeSearchBox() {
      if (searchBox && searchBox.getAttribute('aria-hidden') === 'false') {
        if (!nav.classList.contains('open')) {
          searchBox.style.maxHeight = searchBox.scrollHeight + 'px';
        }
      }
    }
    /* --- Save the height of the header to a CSS variable --- */
    function saveHeaderHeight() {
      let headerHeight = nav.getBoundingClientRect().height;
      if (announcementBanner) {
        const announcementBannerHeight = announcementBanner.getBoundingClientRect().height;
        headerHeight += announcementBannerHeight;
      }
      document.documentElement.style.setProperty('--header-height', headerHeight + 'px');
    }

    // Init announcements banner Flickity
    if (announcementBanner) {
      const announcementBannerFlickity = new Flickity(announcementBanner, {
        cellSelector: '.announcement-banner__slide',
        wrapAround: true,
        cellAlign: 'center',
        prevNextButtons: false,
        pageDots: false,
        autoPlay: 5000,
        selectedAttraction: 0.012,
      });

      // Bind arrows
      const prevBtn = announcementBanner.querySelector('.js-prev');
      const nextBtn = announcementBanner.querySelector('.js-next');
      prevBtn?.addEventListener('click', () => announcementBannerFlickity.previous());
      nextBtn?.addEventListener('click', () => announcementBannerFlickity.next());
    }

    /* Event listeners */
    toggleBtn.addEventListener('click', toggleNav); // mobile nav toggle menu button

    navDropdownLinks.forEach((dropdownLink) => {
      // links that have dropdowns
      dropdownLink.addEventListener('click', toggleSubMenu);
      dropdownLink.addEventListener('mouseover', openSubMenu);
    });

    navLinksAll.forEach((link) => {
      // all links in the nav
      link.addEventListener('focusin', closeSubMenusOnFocus);
    });

    backBtns.forEach((backBtn) => {
      // mobile 'back' buttons
      backBtn.addEventListener('click', closeSubMenus);
    });

    searchBtns.forEach((searchBtn) => {
      // toggle search buttons
      searchBtn.addEventListener('click', () => {
        const isOpen = searchBox.getAttribute('aria-hidden') === 'false';
        if (isOpen) {
          closeSearchBox();
        } else {
          openSearchBox();
        }
      });
    });

    searchCloseBtn.addEventListener('click', closeSearchBox); // close search box

    window.addEventListener('mouseover', (e) => {
      if (!mainNavList.contains(e.target) && window.matchMedia(`(min-width: ${navBp}px)`).matches) {
        closeSubMenus();
      }
    });

    window.addEventListener('resize', () => {
      if (window.matchMedia(`(min-width: ${navBp}px)`).matches) {
        closeMobileNav();
        // reset any sub menus that were opened
        closeSubMenus();
      }
      resizeSearchBox();
      saveHeaderHeight();
    });

    /* --- Sticky nav, showing on scroll up (mobile & desktop) --- */
    let previousPostion = -1;

    window.addEventListener('scroll', () => {
      const windowPosition = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      let startPosition: number = 0;

      if (announcementBanner) {
        let announcementBannerBox = announcementBanner.getBoundingClientRect();
        startPosition = announcementBannerBox.height;
      }

      if (window.matchMedia(`(min-width: ${navBp}px)`).matches) {
        startPosition += nav.getBoundingClientRect().height - mobileNavMenu.getBoundingClientRect().height;
      }

      if (windowPosition > windowHeight) {
        nav.classList.add('fixed');
        nav.classList.remove('top');

        if (windowPosition < previousPostion) {
          nav.classList.add('show');
          document.body.classList.add('body--nav-sticky');
        } else {
          nav.classList.remove('show');
          document.body.classList.remove('body--nav-sticky');
        }
      } else if (windowPosition <= startPosition) {
        nav.classList.remove('show');
        document.body.classList.remove('nav-sticky');
        nav.classList.remove('fixed');
        nav.classList.remove('top');
      } else if (windowPosition < windowHeight) {
        nav.classList.add('top');
      }
      previousPostion = windowPosition;
    });
  }
);
