import Flickity from 'flickity';
import 'flickity-fade';
import { THIN_ARROW_PATH } from '../../../app/Constants/Flickity';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll<HTMLElement>('.js-slider');
  const sliderNavBtns = [].slice.call(document.querySelectorAll('.js-icon-grid-btn'));

  sliders.forEach((elem) => {
    let draggable = elem.dataset.draggable;
    let wrap = elem.dataset.wrap;
    let cellAlign = elem.dataset.align;
    let nextprev = elem.dataset.nextprev;
    let autoPlay = elem.dataset.autoplay;
    let watchCSS = elem.dataset.watchcss;
    let adaptiveHeight = elem.dataset.adaptiveheight;
    let fade = elem.dataset.fade;
    let freeScroll = elem.dataset.freescroll;
    let pageDots = elem.dataset.pagedots;
    let arrowShape = elem.dataset.arrowshape;
    let group = elem.dataset.group;
    const slides = elem.querySelectorAll('.js-slide');

    const flickityOptions: any = {
      cellSelector: '.js-slide',
      lazyLoad: 2,
      imagesLoaded: true,
      selectedAttraction: 0.012,
      // Options passed in
      draggable: draggable === 'false' ? false : '>1',
      wrapAround: wrap ? true : false,
      prevNextButtons: nextprev ? true : false,
      autoPlay: autoPlay ? parseInt(autoPlay) : false,
      watchCSS: watchCSS ? true : false,
      adaptiveHeight: adaptiveHeight ? true : false,
      fade: fade ? true : false,
      freeScroll: freeScroll ? true : false,
      pageDots: pageDots === 'false' ? false : true,
      contain: true,
      on: {
        ready() {
          updateTabIndex(elem, slides);
        },
        change: function(index) {
          updateTabIndex(elem, slides);
          // for elements that have an nav bar, update the selected item on slide change
          if (elem.dataset.nav) {
            updateNav(index, elem);
          }
        },
      },
    };

    if (group) {
      flickityOptions.groupCells = group;
    }
    if (cellAlign) {
      flickityOptions.cellAlign = cellAlign;
    }
    if (arrowShape) {
      flickityOptions.arrowShape = arrowShape;
    }

    let slider = new Flickity(elem, flickityOptions);

    let prev = elem.querySelector('.js-prev');
    let next = elem.querySelector('.js-next');

    prev?.addEventListener('click', () => {
      slider.previous();
    });

    next?.addEventListener('click', () => {
      slider.next();
    });

    let products = elem.querySelector('.hero-slider__products');

    products?.addEventListener('touchstart', () => {
      slider.options.draggable = false;
      slider.updateDraggable();
    });

    products?.addEventListener('touchend', () => {
      slider.options.draggable = true;
      slider.updateDraggable();
    });

    positionSliderBtns();
  });

  // update the current slide when a nav button is used to select the slide
  sliderNavBtns.forEach(function(btn) {
    btn.addEventListener('click', changeSlide);
  });

  window.onresize = positionSliderBtns;

  // Hero product sliders
  const heroProductSliders = document.querySelectorAll('.js-hero-product-slider');
  heroProductSliders.forEach((slider) => {
    new Flickity(slider, {
      draggable: true,
      cellSelector: '.js-hero-product-slide',
      wrapAround: true,
      lazyLoad: 2,
      imagesLoaded: true,
      cellAlign: 'left',
      pageDots: false,
      prevNextButtons: true,
      arrowShape: THIN_ARROW_PATH,
      freeScroll: false,
      autoPlay: false,
      // Using watchCSS to disable flickity when not enough slides to scroll on desktop
      // (with [data-enable-on-desktop] attr)
      watchCSS: true,
    });
  });
});

function changeSlide() {
  const sliderId = this.getAttribute('data-slide');
  const index = this.getAttribute('data-index');
  const slider = document.querySelector(`.js-slider[data-slide="${sliderId}"]`);
  const flkty = Flickity.data(slider);
  flkty.select(index, false, false);

  const allNavBtns = [].slice.call(document.querySelectorAll(`.js-icon-grid-btn[data-slide="${sliderId}"]`));
  allNavBtns.forEach(function(btn) {
    btn.classList.remove('selected');
  });

  this.classList.add('selected');
}

function updateNav(index, slider) {
  const sliderId = slider.getAttribute('data-slide');
  const allNavBtns = [].slice.call(document.querySelectorAll(`.js-icon-grid-btn[data-slide="${sliderId}"]`));
  allNavBtns.forEach(function(btn) {
    let btnIndex = btn.dataset.index;
    if (btnIndex === index) {
      btn.classList.add('selected');
    } else {
      btn.classList.remove('selected');
    }
  });
}

// set no tabindex on non-selected slides, and update tabindex to be clickable on selected slides
function updateTabIndex(slider, slides) {
  slides.forEach(function(slide) {
    const links = slide.tagName === 'A' || slide.tagName === 'BUTTON'
      ? [slide]
      : slide.querySelectorAll('a, button, iframe');
    if (links) {
      links.forEach(function(link) {
        link.setAttribute('tabindex', '-1');
      });
    }
  });

  const selectedSlides = slider.querySelectorAll('.is-selected');

  selectedSlides.forEach(function(slide) {
    const links = slide.tagName === 'A' || slide.tagName === 'BUTTON'
      ? [slide]
      : slide.querySelectorAll('a, button, iframe:not(.js-hidden)');
    if (links) {
      links.forEach(function(link) {
        link.setAttribute('tabindex', '0');
      });
    }
  });
}

// some of the sliders position the buttons centered to the image, not the entire slide

const positionSliderBtns = () => {
  const sliders = document.querySelectorAll('.js-slider[data-centre-btns]');
  sliders.forEach((elem) => {
    const sliderImage = elem.querySelector<HTMLElement>('.js-slide img');
    const btns = elem.querySelectorAll<HTMLElement>('.js-prev, .js-next, .flickity-prev-next-button');
    btns.forEach((btn) => {
      if (sliderImage) {
        btn.style.top = `${sliderImage?.offsetHeight / 2}px`;
      }
    });
  });

  const bottomSliders = document.querySelectorAll('.js-slider[data-bottom-btns]');
  bottomSliders.forEach((elem) => {
    const sliderImage = elem.querySelector<HTMLElement>('.js-slide img');
    const btns = elem.querySelectorAll<HTMLElement>('.js-prev, .js-next, .flickity-prev-next-button');
    btns.forEach((btn) => {
      if (sliderImage) {
        btn.style.top = `${sliderImage?.offsetHeight}px`;
      }
    });
  });
};

// When slider images load we need to fire a resize to adjust slider
let sliderImages = document.querySelectorAll('.js-slide img');
Array.from(sliderImages).forEach((img) => {
  img.addEventListener('load', () => {
    const slider = img.closest('.js-slider');
    const flickity = Flickity.data(slider);
    flickity?.resize();
    positionSliderBtns();
  });
});

// Slide height fix
Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};
Flickity.create._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};
let resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call(this);
  this.element.classList.add('flickity-resize');
};

/* fix iOS touchmove issue */
;
// (function() {
//   let touchingCarousel = false;
//   let startXCoordinate;

//   document.body.addEventListener('touchstart', (e) => {
//     const target = e.target as HTMLButtonElement;
//     if (target?.closest('.flickity-slider')) {
//       touchingCarousel = true;
//     } else {
//       touchingCarousel = false;
//       return;
//     }
//     startXCoordinate = e.touches[0].pageX;
//   });

//   document.body.addEventListener('touchmove', (e) => {
//     if (
//       !touchingCarousel ||
//       !e.cancelable ||
//       Math.abs(e.touches[0].pageX - startXCoordinate) < 8
//     ) {
//       return;
//     }
//     e.preventDefault();
//     e.stopPropagation();
//   }, {
//     passive: false,
//   });
// }());

// /* prevent resize in flickity if still animating */
// const resize = Flickity.prototype.resize;
// Flickity.prototype.resize = function() {
//   if (!this.isAnimating) {
//     resize.call(this);
//   }
// };
