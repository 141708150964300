import { scrollIntoView } from 'seamless-scroll-polyfill';

document.addEventListener('DOMContentLoaded', () => {
  const accBtns = [].slice.call(
    document.querySelectorAll('.js-birthstone-acc-btn')
  );
  const accNavBtns = [].slice.call(
    document.querySelectorAll('.js-birthstone-nav-btn')
  );

  accBtns.forEach(function(btn) {
    btn.addEventListener('click', toggleAcc);
  });

  accNavBtns.forEach(function(btn) {
    btn.addEventListener('click', navigateToAcc);
  });
});

function toggleAcc() {
  let btn = this;
  btn.classList.toggle('active');
  let index = btn.dataset.index;
  let panel = btn.nextElementSibling;
  if (panel.style.maxHeight) {
    btn.classList.remove('active');
    panel.style.maxHeight = null;
  } else {
    openAcc(index);
  }
}

function navigateToAcc() {
  let index = this.dataset.index;
  openAcc(index);
  let accBtn: any = document.querySelector(
    `.js-birthstone-acc-btn[data-index="${index}"]`
  );

  if (accBtn) {
    scrollIntoView(accBtn, { behavior: 'smooth' });
    setTimeout(function() {
      accBtn.focus();
    }, 500);
  }
}

function openAcc(index) {
  // let openAcc = document.querySelector(`.js-birthstone-acc-btn.active`);
  // if (openAcc) {
  // 	openAcc.classList.remove('active');
  // 	let panel: any = openAcc.nextElementSibling;
  // 	if (panel) {
  // 		panel.style.maxHeight = null;
  // 	}
  // }
  let btn = document.querySelector(
    `.js-birthstone-acc-btn[data-index="${index}"]`
  );
  if (btn) {
    btn.classList.add('active');
    let panel: any = btn.nextElementSibling;
    if (panel) {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
    // btn.scrollIntoView({behavior: 'smooth'});
  }
}
