const shareBtns = document.querySelectorAll('.js-share-btn');

shareBtns?.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();

    const list = btn.nextElementSibling;

    list?.classList.toggle('share-btn__list--visible');
  });
});
