import $ from 'jquery';

//smooth scrolling
$('a[href^="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .on('click', (e) => {
    e.preventDefault();

    let id = $(e.currentTarget).attr('href');

    // @ts-ignore
    const target = $(id)[0];

    smoothScroll(target);
  });

function smoothScroll(elem) {
  const $target = $(elem);

  if ($target && $target.length > 0) {
    // item with that id exists

    let navHeight = $('#js-nav').height() ?? 0;

    $('html, body').animate(
      {
        //@ts-ignore
        scrollTop: $target.offset().top - navHeight, // Scroll to this location.
      },
      {
        duration: 800,
        //@ts-ignore
        step: (now, fx) => {
          //  location will change as images etc. are lazy loaded
          //  Where is the target now located on the page?
          // @ts-ignore
          let realPos = $target.offset().top;
          if (fx.end !== realPos) {
            fx.end = realPos - navHeight;
          }
        },
      }
    );
  }
}

export default smoothScroll;
